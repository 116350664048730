import { axiosInstance } from '../../../../services/axios-instance';

interface Payload {
  password: string;
  hash: string;
}
export const ResetPassword = {
  postResetPassword: async (payload: Payload) => {
    return await axiosInstance
      .put('/user/change-password', payload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(response => response)
      .catch(error => error.response);
  },
};
