import React, { useState } from 'react';
import { Layout } from '../components/Layout';
import { ForgotPassword } from '../modules/ForgotPassword/ChangePass';

interface Props {
  location: any;
}
const ForgotPasswordPage = ({ location }: Props) => {
  const [isActive, setIsActive] = useState(true);
  const onClick = () => setIsActive(!isActive);
  const param = location.search.split('=')[1];
  return (
    <Layout
      title="Redefinir senha | Autoscar"
      description="Troque sua senha no site da Autoscar. Utilize uma senha segura e recupere seu acesso e confira os melhores veículos para compra e venda."
    >
      <ForgotPassword onClick={onClick} param={param} />
    </Layout>
  );
};

export default ForgotPasswordPage;
