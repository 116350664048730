import React, { Component } from 'react';
import cx from 'classnames';
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import { Icon } from '../../../components/Icons';
import { ModalSuccessAndError } from '../../../components/ModalSuccessAndError';
import { error400 } from '../../../utils/messagePromises';
import { validationForgotPassword } from '../../../utils/validationForm';
import { TitleForm } from '../../Login/components/TitleForm';
import styles from './ChangePass.module.css';
import { ChangePassForm } from './ChangePassForm';
import { ResetPassword } from './services/ResetPassword';

interface Props {
  onClick?: () => void;
  param: string;
}

export class ForgotPassword extends Component<Props> {
  state = {
    openYourModal: false,
    status: 0,
    messageInfo: 'Sua ação foi concluída.',
  };

  handleSubmit = async (values: { password: string; confirmPassword: string }, actions: any) => {
    const { messageInfo } = this.state;
    const payload = {
      password: values.password,
      hash: this.props.param,
    };
    const response = await ResetPassword.postResetPassword(payload)
      .then(res => res)
      .catch(error => error.response);

    if (response && response.status) {
      this.setState({
        status: response.status,
        messageInfo:
          response.status === 200
            ? messageInfo
            : response.status !== 400
            ? 'Ocorreu um erro inesperado'
            : error400(response),
        openYourModal: true,
      });
    }
    await actions.setSubmitting(false);
  };

  setTitleSuccessOrErrorMessage = () => {
    const { status } = this.state;
    return status === 200 ? 'Sucesso!' : 'Ops!';
  };

  setOpenYourModal = () => {
    this.setState({ openYourModal: false });
  };

  actionRedirect = () => {
    const { status } = this.state;
    return status === 200 ? navigate('/login') : navigate('/esqueci-senha');
  };

  setMessageButton = () => {
    const { status } = this.state;
    return status === 200 ? 'Acessar conta' : 'Solicitar';
  };

  renderMessageSecondary = () => {
    const { status } = this.state;
    return status === 400
      ? 'Solicite novamente a alteração de senha.'
      : status !== 400 && status !== 200
      ? 'Tente novamente mais tarde!'
      : '';
  };
  render() {
    const { onClick } = this.props;
    const { openYourModal, messageInfo, status } = this.state;

    const containerClass = cx(
      'pb32 pb0-ns w-100 h-100 overflow-auto bg-white',
      styles.containerClass,
    );
    const iconClass = cx('db flex justify-center items-center', styles.iconClass);
    const fullPageDiv = cx(
      'bg-top vh-100 cover-ns relative flex justify-center items-end items-center-ns',
      styles.fullPageDiv,
    );
    return (
      <div className={fullPageDiv}>
        <div className={containerClass}>
          <div className="flex justify-center items-center pt32 pt40-ns flex-column">
            <div className={iconClass}>
              <Icon name="SvgIconLogo" />
            </div>
            <div className="w-100 ph16 ph48-ns mt32 mt40-ns mt8-m mt8-l">
              <div className="mb24">
                <TitleForm title="Redefinir senha" />
              </div>
              <Formik
                initialValues={{ password: '', confirmPassword: '' }}
                onSubmit={this.handleSubmit}
                validationSchema={validationForgotPassword}
                // tslint:disable-next-line: jsx-no-lambda
                render={props => <ChangePassForm onClick={onClick} {...props} />}
              />
            </div>
          </div>
        </div>
        <ModalSuccessAndError
          title={this.setTitleSuccessOrErrorMessage()}
          subTitle={messageInfo}
          isOpen={openYourModal}
          success={status === 200 ? true : false}
          closeModal={this.setOpenYourModal}
          actionBtnSecondary={this.setOpenYourModal}
          actionBtn={this.actionRedirect}
          textBtnClose={this.setMessageButton()}
          text={this.renderMessageSecondary()}
        />
      </div>
    );
  }
}
