import React, { FormEvent } from 'react';
import cx from 'classnames';
import { Form, Field } from 'formik';
import { Button } from '../../../components/Button';
import { InputForm } from '../../Login/components/InputForm/InputForm';

export interface Values {
  password: string;
  confirmPassword: string;
}
interface Props {
  errors: object;
  values: Values;
  touched: object;
  isSubmitting?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  onClick?: () => void;
}

interface Field {
  name: string;
  onBlur: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface CustomInputProps {
  divClass: string;
  field: Field;
  onClick: () => void;
  form: any;
  id: string;
  labeltext: string;
  iconleft: string;
  value: string;
}

const customInputComponent = ({ divClass, field, onClick, form, ...props }: CustomInputProps) => {
  const normalizeFieldData = {
    name: field.name,
    onBlur: field.onBlur,
    onChange: field.onChange,
    errormessage: !!form.touched[field.name] && form.errors[field.name],
  };
  const errorsInput = cx({
    mb16: !form.touched[field.name] || (!form.errors[field.name] && !!form.touched[field.name]),
    mb8: !!form.touched[field.name] && form.errors[field.name],
  });
  return (
    <div className={errorsInput}>
      <InputForm {...normalizeFieldData} {...props} />
    </div>
  );
};

export const ChangePassForm = ({ handleChange, onClick, values, isSubmitting }: Props) => {
  return (
    <Form className="w-100">
      <Field
        name="password"
        id="user-password"
        labeltext="Senha"
        iconleft="SvgIconLock"
        iconright="SvgIconShowMe"
        iconrightchange="SvgIconHideMe"
        iconId="exibir-senha"
        iconTitle="Exibir/Esconder senha"
        type="password"
        typeinputchange="text"
        value={values.password}
        onChange={handleChange}
        component={customInputComponent}
        validateOnChange={false}
        required
      />

      <Field
        name="confirmPassword"
        id={'confirm-password'}
        labeltext="Confirmar senha"
        iconleft="SvgIconLock"
        iconright="SvgIconShowMe"
        iconrightchange="SvgIconHideMe"
        iconId="exibir-senha-repetida"
        iconTitle="Exibir/Esconder senha"
        type="password"
        typeinputchange="text"
        value={values.confirmPassword}
        onChange={handleChange}
        component={customInputComponent}
        required
      />
      <div className="flex flex-column w-100 pt8 pb40">
        <Button
          type="submit"
          onClick={onClick}
          loading={isSubmitting}
          aria-label="Redefinir senha"
          title="Redefinir senha"
        >
          REDEFINIR SENHA
        </Button>
      </div>
    </Form>
  );
};
